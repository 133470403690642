import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen1 } from '../../../components/images/support/subscriptions/Screen1.generated';
import { Screen2 } from '../../../components/images/support/subscriptions/Screen2.generated';
import { Screen3 } from '../../../components/images/support/subscriptions/Screen3.generated';
import { Screen4 } from '../../../components/images/support/subscriptions/Screen4.generated';
import { Screen5 } from '../../../components/images/support/subscriptions/Screen5.generated';
import { Screen6 } from '../../../components/images/support/subscriptions/Screen6.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "adding-a-subscription"
    }}>{`Adding a Subscription`}</h1>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the Settings menu.`}</li>
    </ol>

    <Screen1 mdxType="Screen1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`In the Billing section click the "Subscribe" button.`}</li>
    </ol>

    <Screen2 mdxType="Screen2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click "Setup".`}</li>
    </ol>

    <Screen3 mdxType="Screen3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Add your details and then click "Save".`}</li>
    </ol>

    <Screen4 mdxType="Screen4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Enter your credit card details and then click "Save".`}</li>
    </ol>

    <Screen5 mdxType="Screen5" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Click the "Subscribe" button.`}</li>
    </ol>

    <Screen6 mdxType="Screen6" />
    <h1 {...{
      "id": "congrats-your-subscription-is-setup-"
    }}>{`Congrats your subscription is setup 🎉`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      